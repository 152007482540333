import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
import { BiConversation } from "react-icons/bi";
import {
  BriefcaseIcon,
  MailIcon,
  UserCheckIcon
} from "lucide-react"

type Submenu = {
  href: string
  label: string
  active: boolean
  icon: any
}

type Menu = {
  href: string
  label: string
  active: boolean
  icon: any
  submenus: Submenu[]
}

type Group = {
  groupLabel: string
  menus: Menu[]
}

export function getMenuListUser(pathname: string): Group[] {
  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/user/inbox",
          label: "Inbox",
          active: pathname.includes("/user/inbox"),
          icon: MailIcon,
          submenus: [],
        },
        {
          href: "/user/businesses",
          label: "Businesses",
          active: pathname.includes("/user/businesses"),
          icon: BriefcaseIcon,
          submenus: [],
        },
        {
          href: "/user/agents",
          label: "Support Managers",
          active: pathname.includes("/user/agents"),
          icon: UserCheckIcon,
          submenus: [],
        },
        {
          href: "/user/online-users",
          label: "Online Users",
          active: pathname.includes("/user/online-users"),
          icon: MdOutlineMarkUnreadChatAlt,
          submenus: [],
        },
        // {
        //   href: "/user/conversation-track",
        //   label: "Conversation Track",
        //   active: pathname.includes("/user/conversation-track"),
        //   icon: BiConversation,
        //   submenus: [],
        // }
      ],
    },
  ]
}
