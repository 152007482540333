"use client"

import { cn } from "@/lib/utils"
import { useSidebarToggle } from "@/hooks/use-sidebar-toggle"
import { useStore } from "@/hooks/use-store"
import { SiteHeader } from "@/components/site-header"
import { SidebarUser } from "@/components/sidebar-user"

interface DashboardLayoutProps {
  children: any
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const sidebar = useStore(useSidebarToggle, (state) => state)

  if (!sidebar) return null

  return (
    <>
      <SidebarUser />
      <main
        className={cn(
          "min-h-[calc(100vh_-_56px)] transition-[margin-left] ease-in-out duration-300",
          sidebar?.isOpen === false ? "lg:ml-[90px]" : "lg:ml-72"
        )}
      >
        <SiteHeader />
        {children}
      </main>
    </>
  )
}
